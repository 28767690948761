<template>
  <div class="overflow-x-hidden justify-between py-16">
    <h1 class="font-bold text-4xl text-center">
      {{ $t("pages.blog.blogTitle") }}
    </h1>
    <div class="container mx-auto pt-16">
      <div
        class="flex flex-wrap -m-4"
        v-for="(section, index) in Object.keys(entries)"
        :key="index"
      >
        <div
          class="p-4 md:w-1/3"
          v-for="entry in entries[section]"
          :key="entry.id"
        >
          <div
            class="h-full border-2 border-gray-200 rounded-lg overflow-hidden"
          >
            <img
              class="lg:h-56 md:h-48 w-full object-cover object-center"
              :src="getImgUrl(entry.img)"
              v-bind:altl="entry.img"
            />
            <div class="p-6">
              <div
                class="tracking-widest text-xs title-font font-medium text-gray-500 mb-1"
              >
                {{ entry.date }}
              </div>
              <h2 class="title-font text-lg font-medium text-gray-900 mb-3">
                {{ entry.title }}
              </h2>
              <p class="leading-relaxed mb-3">{{ entry.description }}</p>
              <div class="flex items-center flex-wrap ">
                <a
                  class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                  @click="$router.push({ name: entry.id })"
                  >{{ $t("pages.blog.readMore") }}
                  <svg
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BLOGENTRIES from "@/blog/blog.json";

export default {
  computed: {
    entries() {
      return BLOGENTRIES;
    },
  },
  methods: {
    getImgUrl(img) {
      var images = require.context("../blog/img", false, /\.jpg$/);
      return images("./" + img + ".jpg");
    },
  },
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title: locale == "gr" ? "Άρθρα | MedInDiet" : "Blog | MedInDiet",
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/blog" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/blog",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/blog",
        },
      ],
    };
  },
};
</script>
